.category {
    @include flex(column, center, center, 30px);
    position: relative;
    overflow: hidden;
    width: 100%;
    padding: $padding-x 0;

    &__body {
        padding: 0 $padding-x;
        width: 100%;
        max-width: $max-width;
        transition: all 300ms ease-out;

        @media (min-width: ($max-width + 40px)) {
            padding: 0;
        }
    }
}