html {
    scroll-behavior: smooth;
    height: -webkit-fill-available;
}

@supports (-webkit-touch-callout: none) {
    body {
        min-height: -webkit-fill-available;
    }
}

* {
    font-family: 'Rubik', sans-serif;
    font-size: $fs-medium;
    box-sizing: border-box;
}

body {
    position: relative;
    margin: 0;
    color: white;
    background: $clr-bg;
    overflow-x: hidden;

    #root {
        @include flex(column, center, flex-start);
    }
}

::-webkit-scrollbar {
    height: 8px;
    width: 8px;
}

::-webkit-scrollbar-track {
    background:lighten($clr-bg, 10%);
}

::-webkit-scrollbar-thumb {
    background:lighten($clr-bg, 20%);

    &:hover {
        background:lighten($clr-bg, 30%);
    }
}