.item {
    @include flex(row, center, center);
    position: relative;
    overflow: hidden;
    height: 300px;
    flex: 1;
    cursor: pointer;
    transform-origin: bottom;
    transition: all 500ms;

    &.mobile {
        height: 250px;

        &:hover .item__picture {
            &__img {
                filter: brightness(100%);
            }
        }
    }

    &.tablet {
        height: 300px;

        &:hover {
            height: 350px;
        }
    }

    &.desktop {
        height: 350px;

        &:hover {
            height: 400px;
        }
    }

    &:not(.mobile):hover {
        .item__picture {
            &__img {
                filter: brightness(100%);
            }
    
            &__title span {
                text-shadow: 0px 0px 10px rgba(0,0,0,1);
            }
        }

        .item__img {
            filter: blur(50px) brightness(100%);
        }
    }

    &__picture {
        position: relative;
        height: 100%;
        width: 100%;
        max-width: $max-width;

        &__img {
            @include placeholder(0.8);
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            background-color: lighten($clr-bg, 15%);
            filter: brightness(75%);
            transition: all 500ms;

            &:not(.mobile) {
                mask-image: linear-gradient(90deg, rgba(0,0,0,1) 80%, rgba(80,80,80,0) 100%), linear-gradient(270deg, rgba(0,0,0,1) 80%, rgba(80,80,80,0) 100%);
                mask-composite: destination-in;
            }
        }

        &__title {
            @include flex(row, center, space-between, 10px);
            width: 100%;
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 1;
            margin: 0;
            padding: 25px;
            transition: all 500ms;

            @media (min-width: ($max-width + 40px)) {
                padding: 25px 0;
            }

            &.mobile * {
                text-shadow: 0px 0px 20px rgba(0,0,0,1);
            }

            &.desktop span {
                font-size: $fs-xlarge;
            }

            &:not(.desktop) span {
                font-size: $fs-large;
            }

            span {
                transition: all 500ms;
            }
        }

        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            background: linear-gradient(0deg, rgba(0,0,0,.5) 20%, rgba(80,80,80,0) 80%);
            height: 100px;
            width: 100%;
            filter: opacity(0);
            transition: all 500ms;
        }
    }

    &__img {
        @include placeholder(0.8);
        position: absolute;
        z-index: -1;
        width: 100%;
        background-color: lighten($clr-bg, 30%);
        filter: blur(50px) brightness(75%);
        transition: all 500ms;

        &.mobile {
            height: 250px;
        }
    
        &.tablet {
            height: 300px;
        }
    
        &.desktop {
            height: 350px;
        }
    }
}