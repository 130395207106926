.project {
    &__banner {
        position: relative;
        height: 250px;
        width: 100%;
        margin-top: -10px;

        &__img {
            @include placeholder(0.8);
            height: 100%;
            width: 100%;
            object-fit: cover;
            object-position: center;
            filter: blur(20px);
            mask-image: linear-gradient(180deg, $clr-bg 100%, rgba(80,80,80,0) 100%);
            -webkit-mask-composite: destination-in;
            mask-composite: exclude;
        }
    }

    &__pictures {
        @include flex(row, center, flex-start, 10px);
        position: absolute;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        bottom: -30px;
        width: 100%;
        max-width: $max-width;
        padding: 0;
        overflow-x: auto;
        scroll-padding-bottom: 20px;
        transition: all 300ms ease-out;

        @media (max-width: ($max-width + 40px)) {
            padding: 0 $padding-x;
        }

        &__img {
            @include placeholder(0.5);
            height: 150px;
            min-width: 300px;
            width: 300px;
            max-width: 85%;
            object-fit: cover;
            object-position: center;
            border-radius: 5px;
            box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
            cursor: pointer;
            transition: all 500ms;

            &:hover {
                filter: brightness(80%);
            }
        }

        &::-webkit-scrollbar {
            height: 5px;
        }
        
        &::-webkit-scrollbar-track {
            background:lighten($clr-bg, 10%);
        }
        
        &::-webkit-scrollbar-thumb {
            background:lighten($clr-bg, 20%);
        
            &:hover {
                background:lighten($clr-bg, 30%);
            }
        }
    }

    .title {
        margin-top: 20px;
        padding-bottom: 20px;
        line-height: 35px;

        &.text_filled {
            border-bottom: 2px solid lighten($clr-bg, 10%);
        }
    }

    &__video {
        @include flex(row, flex-start, flex-start);
        width: 100%;
        max-width: $max-width;
        padding: 0 $padding-x;
        margin-top: -22px;
        transition: all 300ms ease-out;

        @media (min-width: ($max-width + 40px)) {
            padding: 0;
        }

        iframe {
            min-height: 200px;
            width: 100%;
            max-width: 400px;
            background: lighten($clr-bg, 15%);
            border-radius: 10px;
            overflow: hidden;
        }
    }

    &__description {
        padding: 0 $padding-x;
        margin: 0;
        width: 100%;
        max-width: $max-width;
        transition: all 300ms ease-out;

        &,
        * {
            font-size: $fs-large;
        }

        @media (min-width: ($max-width + 40px)) {
            padding: 0;
        }

        a {
            position: relative;
            color: lighten($clr-bg, 60%);
            transition: all 500ms;
            text-decoration: none;

            &::after {
                content: "";
                position: absolute;
                bottom: -2px;
                left: 0;
                height: 2px;
                width: 100%;
                background: lighten($clr-bg, 60%);
                transition: all 500ms;
            }

            &:hover {
                color: white;

                &::after {
                    background: white;
                }
            }
        }
    }
}