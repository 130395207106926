.devis {
    &__disclaimer {
        color: lighten($clr-bg, 45%);
        margin: -15px 0 30px 0;

        span {
            color: rgb(255, 100, 150);
        }
    }

    &__field {
        @include flex(column, flex-start, flex-start, 8px);

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }

        &.alt {
            display: none;
        }

        &__label {
            color: lighten($clr-bg, 75%);
        }

        textarea {
            resize: vertical;
            min-height: 150px;
        }
    }

    &__field__input,
    &__submit {
        width: 100%;
        background: lighten($clr-bg, 10%);
        color: lighten($clr-bg, 75%);
        border: none;
        border-radius: 5px;
        padding: 10px 12px;
    }

    &__submit {
        margin-top: 20px;
        cursor: pointer;
        transition: all 500ms;

        &:hover {
            background: lighten($clr-bg, 15%);
        }
    }

    &__error,
    &__success {
        padding: 15px;
        border-radius: 5px;
    }

    &__error {
        background: rgb(255, 0, 80);
    }

    &__success {
        background: rgb(0, 255, 123);
        color: black;
    }
}