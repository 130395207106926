.lightbox {
    @include flex(row, center, center);
    position: fixed;
    z-index: 9;
    height: 100%;
    width: 100%;
    transition: all 500ms;

    &:not(.visible) {
        transform: translateY(-100%);
    }

    &.visible {
        transform: translateY(0);
    }

    &__background {
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(20px);
    }

    &__picture {
        @include flex(column, center, center);
        position: absolute;
        top: 0;
        bottom: 0;
        margin-top: auto;
        margin-bottom: auto;

        img {
            @include placeholder(0.8);
            max-height: 90%;
            width: 90%;
            object-fit: contain;
            object-position: center;
        }
    
        &__buttons {
            @include flex(row, center, space-between);
            width: 90%;
            overflow: hidden;

            &:first-of-type {
                border-radius: 10px 10px 0 0;
            }

            &:last-of-type {
                border-radius: 0 0 10px 10px;
            }

            &.disabled {
                *,
                *:hover {
                    filter: brightness(100%) !important;
                    cursor: initial !important;
                    color: lighten($clr-bg, 25%);
                }
            }
    
            &__item {
                background: $clr-bg;
                color: white;
                border: none;
                width: 50%;
                padding: $padding-x * .75;
                transition: all 500ms;

                &:first-of-type {
                    @include flex(row, center, flex-start, 10px);
                    border-right: 2px solid lighten($clr-bg, 5%);
                }
                
                &:last-of-type {
                    @include flex(row, center, flex-end, 10px);
                }

                &:not(.disabled):hover {
                    filter: brightness(80%);
                    cursor: pointer;
                }
    
                * {
                    color: white;
                }

                span {
                    &:last-of-type {
                        color: lighten($clr-bg, 50%);
                        margin-left: -4px;
                    }
                }
            }
        }
    }
}