.header {
    @include flex(row, center, center);
    z-index: 9;
    width: 100%;
    border-bottom: 2px solid darken(white, 75%);
    transition: all 300ms;

    .header__container {
        @include flex(row, center, space-between);
        width: 100%;
        max-width: $max-width;
        padding: $padding-x;
        transition: all 300ms;

        @media (min-width: ($max-width + 40px)) {
            padding: $padding-x 0;
        }

        &__button {
            @include flex(row, center, flex-start, 10px);
            font-size: $fs-large;
            background: none;
            color: white;
            border: none;
            padding: 0;
            cursor: pointer;
            transition: all 500ms;

            &:hover {
                color: darken(white, 20%);
            }
        }
    }
}