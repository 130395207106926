$height: 200px;
$width: 160px;

.polaroid {
    @include flex(column, center, space-between);
    color: black;
    background: white;
    box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.8);
    transition: all 300ms;

    &.small {
        height: $height;
        width: $width;
        padding: $width / 30;
    }

    &.large {
        height: $height;
        width: $width * 1.5;
        padding: ($width * 1.5) / 45;
    }

    &__image {
        @include placeholder(0.5);
        object-fit: cover;
        object-position: center;
        height: 85%;
        width: 100%;
        box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.15);
        transition: all 500ms ease-in-out 250ms;
    }

    &__legend {
        margin-bottom: 7px;
        font-family: 'Caveat';
    }
}