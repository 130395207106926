.intro {
    @include flex(column, center, center);
    position: relative;
    min-height: 550px;
    height: 100vh;
    width: 100vw;

    *:not(.intro__title) {
        font-size: $fs-medium * 1.1;
    }

    .intro__title {
        font-size: $fs-xxlarge;
    }

    *:not(img):not(video) {
        font-family: 'DM Sans';
        transition: all 300ms;
    }

    &__container {
        width: 100%;
        max-width: $max-width;
        margin-top: -100px;
    }

    &__title {
        font-size: $fs-xxlarge;
        font-weight: 700;
        margin: 0 $padding-x * 1.5;
    }

    &__baseline {
        font-weight: 500;
        margin: 0 $padding-x * 1.5;
        padding-bottom: 15px;
        border-bottom: 2px solid darken(white, 75%);

        span {
            &:first-of-type {
                color: darken(white, 20%);
            }
    
            &:last-of-type {
                font-style: italic;
            }
        }
    }

    &__definition {
        margin: 15px $padding-x * 1.5 0 $padding-x * 1.5;

        *::after {
            display: none;
        }
    }

    &__background {
        position: absolute;
        object-fit: cover;
        object-position: center;
        height: 100%;
        width: 100%;
        z-index: -1;
        filter: blur(10px);
        mask-image: linear-gradient(180deg, $clr-bg 80%, rgba(80,80,80,0) 100%);
        -webkit-mask-composite: destination-in;
        mask-composite: exclude;
    }

    &__video {
        height: 225px;
        border-radius: 5px;
        transform: translateZ(0);
        background: black;
        object-fit: cover;
        box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.25);
        cursor: pointer;
        transition: all 800ms;

        @media (min-width: 510px) {
            margin: 30px $padding-x * 1.5 0 $padding-x * 1.5;
            width: 450px;
        }

        @media (max-width: 510px) {
            margin: 30px 0 0 0;
            width: 100%;
            border-radius: 0;
        }

        &:not(.animated) {
            filter: opacity(0%);
        }

        &.animated {
            filter: opacity(100%);
        }
    }

    &__buttons {
        @include flex(row, flex-start, flex-start, 5px);
        flex-wrap: wrap;
        margin: 30px $padding-x * 1.5 0 $padding-x * 1.5;

        &__button {
            padding: 8px 12px;
            border: none;
            border-radius: 5px;
            white-space: nowrap;
            text-decoration: none;
            color: white;
            background: lighten($clr-bg, 10%);
            cursor: pointer;
            transition: all 500ms;

            &:hover {
                background: lighten($clr-bg, 20%);
            }
        }
    }
}