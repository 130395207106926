.ressource {
    width: 100%;

    &__buttons {
        @include flex(row, center, flex-start, 5px);
        position: relative;
        width: 100%;
        max-width: $max-width;
        padding: 0 $padding-x 20px $padding-x;
        margin: 0 $padding-x -20px $padding-x;
        overflow-x: auto;
        transition: all 300ms ease-out;

        @media (min-width: ($max-width + 40px)) {
            padding: 0 0 20px 0;
        }

        &::-webkit-scrollbar {
            height: 5px;
        }
        
        &::-webkit-scrollbar-track {
            background:lighten($clr-bg, 10%);
        }
        
        &::-webkit-scrollbar-thumb {
            background:lighten($clr-bg, 20%);
        
            &:hover {
                background:lighten($clr-bg, 30%);
            }
        }
    }
}