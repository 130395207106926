// Page
$padding-x: 20px;
$max-width: 1000px;

// Colors
$clr-bg: #171717;
$clr-primary-text: #c9d4ff;
$clr-grey: #cacaca;

// Font size
$fs-xxsmall: 10px;
$fs-xsmall: 12px;
$fs-small: 14px;
$fs-medium: 16px;
$fs-large: 20px;
$fs-xlarge: 22px;
$fs-xxlarge: 30px;