.title {
    width: 100%;
    max-width: $max-width;
    padding: 0 $padding-x;
    margin-bottom: -10px;
    transform-origin: top;
    transition: all 300ms ease-out;

    @media (min-width: ($max-width + 40px)) {
        padding: 0;
    }

    &__h2 {
        font-family: 'DM Sans';
        font-weight: 500;
        font-size: $fs-xxlarge;
        margin: 0;
        transition: all 500ms;
    }

    &__date {
        margin: 0;
        color: lighten($clr-bg, 60%);
    }
}