@mixin flex($direction, $align, $justify, $gap: 0) {
    display: flex;
    flex-direction: $direction;
    align-items: $align;
    justify-content: $justify;
    gap: $gap;
}

@mixin placeholder($brightness) {
    background: linear-gradient(rgba(0, 0, 0, $brightness), rgba(0, 0, 0, $brightness)), url('../../assets//placeholder.gif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}