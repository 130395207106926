.profile {
    margin-top: 20px;

    &__polaroids {
        position: relative;
        width: max-content;
        margin-bottom: 20px;
        z-index: -1;
        transition: all 1s;

        &.mobile {
            @include flex(column, center, center);
        }

        &:not(.mobile) {
            @include flex(row, center, flex-start);
        }

        &.animated img {
            filter: opacity(100%);
        }

        &:not(.animated) img {
            filter: opacity(20%);
        }

        &__decoration {
            position: absolute;
            left: calc(-50% + 90px);
            z-index: -1;
            transition: all 500ms;

            &.mobile {
                top: 150px;
                left: calc(-50%);
                transform: rotate(15deg);
            }

            &:not(.mobile) {
                left: calc(-50% + 90px);
                transform: rotate(-3deg);
            }
        }

        &::before {
            content: "";
            position: absolute;
            left: calc(-50% + 80px);
            background: linear-gradient(90deg, $clr-bg 30%, rgba(80,80,80,0) 100%);
            height: 100%;
            width: 80px;
        }

        &::after {
            content: "";
            position: absolute;
            right: calc(-50% + 80px);
            background: linear-gradient(270deg, $clr-bg 30%, rgba(80,80,80,0) 100%);
            height: 100%;
            width: 80px;
        }
    }

    &__block {
        position: relative;
        transition: all 500ms;

        &.desktop {
            font-size: $fs-xlarge;

            &:not(:last-of-type) {
                margin-bottom: 100px;
            }

            &.left {
                @include flex(row, center, space-between, 180px);
                text-align: end;
            }

            &.right {
                @include flex(row-reverse, center, space-between, 180px);
                text-align: start;
            }
        }

        &:not(.desktop) {
            font-size: $fs-large;

            &:not(:last-of-type) {
                margin-bottom: 80px;
            }

            &.left,
            &.right {
                @include flex(column, center, center, 50px);
                text-align: center;
            }
        }

        &.left .profile__block__polaroids.animated,
        &.right .profile__block__polaroids.animated {
            transform: translateX(0);
        }

        &.left .profile__block__polaroids:not(.animated) {
            transform: translateX(-100vw);
        }

        &.right .profile__block__polaroids:not(.animated) {
            transform: translateX(100vw);
        }

        &__polaroids {
            height: fit-content;
            transition: all 500ms 250ms;

            .i-1 {
                margin: 0 0 0 -100px;
                transform: rotate(-5deg);
            }

            .i-2 {
                margin: -170px -100px 0 120px;
                transform: rotate(5deg);
            }

            .i-3 {
                margin: 0 0 0 -60px;
                transform: rotate(-3deg);
            }

            .i-4 {
                margin: -180px -60px 0 80px;
                transform: rotate(3deg);
            }

            .i-5 {
                margin: 0 0 0 -70px;
                transform: rotate(3deg);
            }

            .i-6 {
                margin: -150px -70px 0 80px;
                transform: rotate(-3deg);
            }

            .i-7 {
                margin: 0 0 0 -60px;
                transform: rotate(3deg);
            }

            .i-8 {
                margin: -180px -60px 0 100px;
                transform: rotate(-3deg);
            }
        }
    }

    &__quote {
        @include flex(column, center, flex-start);
        position: relative;
        width: 100%;
        background: lighten($clr-bg, 5%);
        transition: all 500ms;

        &:not(.desktop) {
            margin: 30px 0 0 0;
            padding: $padding-x;

            p,
            footer,
            footer * {
                font-size: $fs-large;
            }
        }

        &.desktop {
            margin: 50px 0 0 0;
            padding: $padding-x * 1.5;

            p,
            footer,
            footer * {
                font-size: $fs-xlarge;
            }
        }

        p,
        footer,
        footer * {
            z-index: 2;
            width: 100%;
            max-width: $max-width;
        }

        p {
            margin-top: 0;
        }

        footer {
            color: $clr-grey;
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            filter: brightness(25%);
            mask-image: linear-gradient(90deg, rgba(0,0,0,1) 80%, rgba(80,80,80,0) 100%);
            -webkit-mask-composite: destination-in;
            mask-composite: exclude;
            filter: brightness(25%);
            transition: all 500ms;
        }
    }
}