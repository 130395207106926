.footer {
    @include flex(column, center, flex-start);
    position: absolute;
    bottom: -350px;
    width: 100%;
    background: lighten($clr-bg, 5%);
    padding: $padding-x * 2 $padding-x;

    &__networks {
        @include flex(row, center, flex-start, 5px);
        flex-wrap: wrap;
        margin-bottom: 15px;

        &__item {
            @include flex(row, center, center);
            background: lighten($clr-bg, 15%);
            color: white;
            height: 40px;
            width: 40px;
            padding: 10px;
            border-radius: 5px;
            text-decoration: none;
            cursor: pointer;
            transition: all 500ms;

            &:hover {
                background: lighten($clr-bg, 25%);
            }

            * {
                font-size: 20px;
            }
        }
    }

    *:not(.footer__networks__item) {
        width: 100%;
        max-width: $max-width;
    }

    a:not(.footer__networks__item) {
        position: relative;
        color: lighten($clr-bg, 70%);
        text-decoration: none;

        &:hover::after {
            width: 100%;
        }

        &::after {
            content: "";
            position: absolute;
            bottom: -2px;
            left: 0;
            height: 2px;
            width: 0%;
            background: lighten($clr-bg, 70%);
            transition: all 500ms;
        }
    }
}